
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';

@Component({
  components: {
  }
})
export default class Aggregation extends Mixins(utilMixins) {
  str = 'Edit Your Content Here!';

  get config() {
    return {
      events: {
        'froalaEditor.initialized': function () {
          console.log('initialized')
        }
      },
      "language": "ja",
      toolbarButtons: {
        'moreText': {
          //'fontFamily', 'inlineClass', 'inlineStyle'
          'buttons': ['fullscreen', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'clearFormatting'],
          'buttonsVisible': 4
        },
        'moreParagraph': {
          'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'formatOLSimple', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'lineHeight', 'outdent', 'indent', 'quote'],
          'buttonsVisible': 3
        },
        'moreRich': {
          'buttons': ['insertTable', 'emoticons', 'specialCharacters', 'insertHR'],
          'buttonsVisible': 4
        },
        'moreMisc': {
          'buttons': ['undo', 'redo', 'help'],
          'align': 'right',
          'buttonsVisible': 3
        }
      }
    };
  }
  get model() {
    return this.str;
  }
  set model(val) {
    this.str = val;
  }
}
